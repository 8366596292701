<template>
  <section class="container-fluid bg-img-one mt-20 md:mt-32 pb-10" >
    <div class="container mx-auto px-5">
      <div class="text-center">
        <h3 class="font-GothamBook small-headline mb-8 -mt-12 text-blue-700-dark" font-display="swap">all in one, easy to use platform.</h3>

        <h1 class="font-GothamBold text-white headline-1" font-display="swap">Staffing Made Easy.</h1>

        <div class="w-full mt-3 hidden md:block">
          <div class="font-GothamBold md:flex lg:flex justify-center items-center" font-display="swap">
            <h2 class="home-headline-2 text-white text-center pt-2 pr-1 md:pt-0 md:pr-2 flex justify-center">
              <span>All in one</span><span class="ml-4 mr-2 hidden md:block lg:block">-</span> 
            </h2>
            <div class="flex justify-center mt-2">
              <vue-typed-js ref="typedJs" :loop="true" :strings="slogens" :typeSpeed="100" :backDelay="1500">
                <span class="typing home-headline-2 justify-start" id="story"></span>
              </vue-typed-js>
            </div>
          </div>
        </div>

        <div class="w-full mt-3 block md:hidden">
          <div class="font-GothamBold md:flex lg:flex justify-center items-center" font-display="swap">
            <h2 class="home-headline-2 text-white text-center pt-2 pr-1 md:pt-0 md:pr-2 flex justify-center">
              <span>All in one</span>
            </h2>
          </div>
          <div class="flex justify-center font-GothamBold h-16" font-display="swap">
            <vue-typed-js ref="typedJs" :loop="true" :strings="slogens" :typeSpeed="100" :backDelay="1500">
              <span class="typing home-headline-2" id="story"></span>
            </vue-typed-js>
          </div>
        </div>

        <p class="font-GothamBook text-white home-subtitle mt-5 md:mt-9" font-display="swap">
          Temp button transforms complicated tech stacks for timesheets, invoices, and payroll <br class="hidden md:block">  into an automated system with total user control. Preview it today.
        </p>

        <div class="flex items-center justify-center mt-5 md:mt-12">
          <button onclick="window.App.event(window.App.analytics, 'book_demo_click', { clicked: 'true' }); Calendly.initPopupWidget({url: 'https://calendly.com/mariotempbutton/demo-with-mario-temp-button'}); return false;" class="w-56 py-2 border-2 border-blue-700 bg-blue-700 focus:outline-none rounded-full text-center text-white font-medium hover:border-blue-700-dark hover:bg-blue-700-dark transition-all duration-200 ease-in text-lg cursor-pointer">Book a Demo Now</button>
          <button onclick="window.location.href='https://timesheet.tempbutton.com/signup'" class="ml-4 w-56 py-2 border-2 border-blue-700 bg-blue-700 focus:outline-none rounded-full text-center text-white font-medium hover:border-indigo-700-dark hover:bg-blue-700-dark transition-all duration-200 ease-in text-lg cursor-pointer">Try for free</button>
        </div>

        <div class="flex justify-center mt-10">
          <div class="w-5/6 video-div">
            <video controls muted ref="videoRef" class="z-10 w-auto video-border" id="video" poster="../../assets/images/thumbnail.webp">
              <source src="@/assets/intro2.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>

    <!--<video-modal :displayModal="scrolled"></video-modal>-->
    <popup-try-for-free @success-sign-up="showConfirmationDialog"></popup-try-for-free>
    <popup-message></popup-message>
  </section>
</template>

<script>
//import videoModal from "./video-modal.vue";
import popupTryForFree from './popupTryForFree.vue';
import PopupMessage from './PopupMessage.vue';

export default {
  components: {
    popupTryForFree,
    PopupMessage
  },

  data() {
    return {
      slogens: [
        'Onboarding',
        'Timesheets',
        'Approval',
        'Payroll',
        'Invoices',
      ],
      currentSlide: 0,
      videoPlayer: true,
      scrolled: false
    }
  },

  methods: {
    videoPlayed(){
      window.App.event(window.App.analytics, 'played_video', { clicked: 'true' }); 
      this.videoPlayer = false;
    },
    handleScroll() {
      let yAxisHeight = Math.round(window.pageYOffset);
      if(yAxisHeight > 1000 && yAxisHeight < 2000 && this.videoPlayer){
        this.scrolled = true;
        this.videoPlayer = false;
      }
    },
    showDialogBox() {
      window.App.event(window.App.analytics, 'try_for_free_click', { clicked: 'true' });

      this.$modal.show('try-for-free-modal', {});
    },
    showConfirmationDialog() {
      window.App.event(window.App.analytics, 'try_for_free_finished', { clicked: 'true' });
      this.$modal.show('general-message-modal', {
          title: 'Success',
          subtitle: '',
          message: "Please check your email to view and approve a sample timesheet."
      });
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted () {
    //this.$refs.videoRef.play(); 
  },
};
</script>
